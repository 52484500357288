import clsx from 'clsx'
import Tooltip from 'components/shared/tooltip'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { SpaceFile } from '../../../stores/files/file'
import { baseClassNames } from '../spaceroom/file'
import { Line } from '../spaceroom/line'
import { Row } from '../spaceroom/row'
import { getFileSize } from './file-size'
import FileImage from 'components/dataroom/spaceroom/file-image'

export interface TrashProps {
    file: SpaceFile
    selected: boolean
    onSelect: (file: SpaceFile) => void
    onDeselect: (file: SpaceFile) => void
}

const TrashFile = observer(({ selected, file, onSelect, onDeselect }: TrashProps) => {
    const { t } = useTranslation()

    const [isOver, setOver] = useState<boolean>(false)
    const [checked, setChecked] = useState<boolean>(false)

    const changeSelection = newValue => {
        newValue ? onSelect(file) : onDeselect(file)
        setChecked(newValue)
    }

    const toggleChecked = () => {
        const newValue = !checked
        changeSelection(newValue)
    }

    useEffect(() => {
        setChecked(selected)
    }, [selected])

    return (
        <Line fileOrDirectory={file}>
            <Row
                className={clsx(
                    'flex items-center justify-center border-l-2 border-christine',
                    isOver || checked ? 'border-r-0 bg-christine' : '',
                    baseClassNames
                )}
                onMouseEnter={() => setOver(true)}
                onMouseLeave={() => setOver(false)}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => toggleChecked()}
                    className={`border-white text-christine ${isOver || checked ? 'visible' : 'invisible'}`}
                />
            </Row>
            <Row className={clsx('col-span-8 flex items-center gap-3', baseClassNames)}>
                <FileImage file={file} filename={file.name} />
                <span className="mr-2 truncate">{file.name}</span>
            </Row>
            <Row className={clsx('col-span-6 flex', baseClassNames)}>
                <span className="mr-2 truncate">
                    {file.space.displayName} &gt; {file.directory.name}
                </span>
            </Row>
            <Row className={clsx('col-span-3 text-center', baseClassNames)}>{getFileSize(t, file.size)}</Row>
            <Row className={clsx('col-span-4 text-center', baseClassNames)}>
                <Tooltip tooltip={file.updatedAt.toLocaleString(DateTime.DATETIME_MED)}>
                    <div>
                        <span className="hidden xl:block">{file.updatedAt.toLocaleString(DateTime.DATE_SHORT)}</span>
                        <span className="xl:hidden">{file.updatedAt.year}</span>
                    </div>
                </Tooltip>
            </Row>
        </Line>
    )
})

export default TrashFile
