import { clsx } from 'clsx'
import { observer } from 'mobx-react-lite'
import type { SpaceFile } from 'stores/files/file'
import DownloadLink from '../../dataroom/spaceroom/download-link'
import SharedLinePart, { type LineProps } from './shared-line-part'
import FileImage from 'components/dataroom/spaceroom/file-image'

interface FileLineProps extends LineProps {
    file: SpaceFile
}

const FileLine = observer(
    ({
        file,
        users,
        owner,
        partner,
        className,
        level,
        sharedFile,
        showUserSharedByMe,
        showUserSharedWithMe,
        setDownloading,
        setShowConfirmStop,
        setShowRestart,
        setShowConfirmDelete,
    }: FileLineProps) => {
        return (
            <SharedLinePart
                fileOrDirectory={file}
                className={clsx(className)}
                level={level}
                sharedFile={sharedFile}
                users={users}
                owner={owner}
                partner={partner}
                showUserSharedByMe={showUserSharedByMe}
                showUserSharedWithMe={showUserSharedWithMe}
                setDownloading={setDownloading}
                setShowConfirmStop={setShowConfirmStop}
                setShowRestart={setShowRestart}
                setShowConfirmDelete={setShowConfirmDelete}
            >
                <FileImage
                    file={file}
                    filename={file.displayName}
                    className={sharedFile.canDownload ? '' : 'opacity-70'}
                />
                <DownloadLink
                    href={file.downloadUrl}
                    disabled={!sharedFile.canDownload}
                    className={clsx(
                        sharedFile.canDownload ? 'text-inner-space' : 'text-regent-gray',
                        'max-w-very-xs flex-1 grow truncate md:max-w-xs lg:max-w-md xl:max-w-none'
                    )}
                >
                    {file.displayName}
                </DownloadLink>
            </SharedLinePart>
        )
    }
)

export default FileLine
