export default function Treasy({ className }: { className?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.604 157.233" className={className}>
            <path
                fill="none"
                stroke="#fca869"
                strokeWidth="1.5"
                d="m57.144 40.848-5.406-.277c-1.977-.192-3.514 1-4.756 3.526l-15.049 23.1c-1.347 2.28-5.936 1.683-3.834-2.383l7.564-16.056-5.281-.138a9.167 9.167 0 0 0-3.091.729L5.325 57.191S.752 58.445.752 66.732c0 3.61 1.947 23.051 1.947 23.051 0 2.4 2.16 4.493 4.272 5.072 1.268.348 7.252.093 7.252.093.915-1.221-.1-6.2.717-7.6 2.606-4.447 5.874-1.293 4.216 2.223L7.2 113.107c-2.107 3.9.131 5.967.759 6.725 3.026 3.653 9.148 8.331 16.254 9.438a74.328 74.328 0 0 0 9.924.358c.8-.839 23.03-17.462 23.379-18.3.044-.1.073-.214.116-.317a63.751 63.751 0 0 0 8.812-23.1c5.41-29.67-9.298-47.057-9.3-47.063Z"
            />
            <path
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                d="M60.203 42.324s-3.8-4.3-7.553 1.775l-15.049 23.1c-1.347 2.28-5.937 1.683-3.834-2.383l4.131-9.324c2.318-4.675-1.413-7.938-4.28-6.391L10.99 57.187s-4.568 1.256-4.568 9.543c0 3.61 1.947 23.051 1.947 23.051 0 3.833 5.529 6.9 7.547 4.212.915-1.221 3.875-5.246 4.694-6.642 2.606-4.447 5.874-1.293 4.216 2.223L12.87 113.11c-2.107 3.9.132 5.968.759 6.725a28.857 28.857 0 0 0 16.254 9.438c11.619 1.969 23.222-4.283 31.477-15.421a8.827 8.827 0 0 0 1.825-2.518c.043-.1.073-.214.116-.318a63.753 63.753 0 0 0 8.812-23.1c5.409-29.676-11.908-45.59-11.91-45.592Z"
            />
            <path
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                d="M103.959 52.011c-3.245 19.151-7.587 87.641-20.746 93.008 0 0-14.052-3.816-19.057-4.664-18.911-3.2-30.665-26.913-26.253-52.957s23.319-44.559 42.23-41.355 23.826 5.968 23.826 5.968Z"
            />
            <ellipse
                cx="47.828"
                cy="34.729"
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                rx="47.828"
                ry="34.729"
                transform="rotate(-80.385 106.879 40.626)"
            />
            <path
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                d="M133.153 57.711a7.3 7.3 0 0 0-1.9-7.77c-2.694-3.389-7.44-8.4-11.124-13.053s-.051-9.17 3.5-4.237l2.263 3.092c.467.62 2.835 3.384 3.565 4.111 1.661 1.653 6.553 1.274 6.553 1.274 1.093-3.347 2.106-30.075 2.106-37.291l-5.7-1.876c-2.433-1.058-6.026-.148-6.026-.148s-18.74 3.033-19.622 3.282c-3.742 1.054-3.2 5.213-1.493 6.894l3.533 3.475c2.486 2.9.17 7.26-3.621 3.716L93.771 4.202l-6.275-2.756c-3.718-2.156-5.666.771-9.067 6.011a7.6 7.6 0 0 0-.408.8 61.378 61.378 0 0 0-9.026 23.309c-3.54 20.894 3.325 40.285 15.943 48.791 3.11 2.1 12.845 6.288 16.584 6.922 0 0 23.236-11.014 31.1-28.24.014-.022.047-.047.058-.068.054-.105.066-.2.115-.3.116-.26.247-.506.36-.768.012-.048-.01-.13-.002-.192Z"
            />
            <path
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                d="M139.429 60.47a7.3 7.3 0 0 0-1.905-7.77c-2.694-3.389-7.44-8.4-11.124-13.053s.926-8.721 4.479-3.788l3.2 3.955c1.22 2.066 3.578 2.73 4.66-5.469 1.005-7.617 2.247-18.21 2.4-24.049.2-7.673-5.508-6.826-5.508-6.826s-15.693 2.269-22.968 3.9c-3.37.755-4.455 3.782-1.586 6.355l3.415 3.588c4.426 4.425.752 8.172-3.04 4.628L93.76 4.207c-3.718-2.156-5.666.771-9.066 6.011a7.6 7.6 0 0 0-.408.8 61.38 61.38 0 0 0-9.026 23.309c-4.412 26.043 7.341 49.753 26.252 52.957 14.766 2.5 29.511-8.257 37.374-25.484.014-.022.047-.047.058-.068.054-.105.066-.2.115-.3.116-.26.246-.506.36-.768.024-.053.002-.135.01-.194Z"
            />
            <path
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                d="M129.454 57.561c-3.245 19.151-7.587 87.641-20.745 93.008 0 0-14.052-3.816-19.057-4.664-18.911-3.2-30.665-26.913-26.253-52.956s23.319-44.559 42.23-41.355 23.825 5.967 23.825 5.967Z"
            />
            <ellipse
                cx="47.828"
                cy="34.729"
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                rx="47.828"
                ry="34.729"
                transform="rotate(-80.385 122.91 28.312)"
            />
            <path
                fill="#f8f9fa"
                stroke="#fca869"
                strokeWidth="1.5"
                d="M136.605 64.357c-17.864-3.026-35.726 13.329-41.367 37.1-.01.043-1.287 6.225 2.241 8.271 2.63 1.526 13.762 7.8 15.4 8.847 5.949 3.782.622 8.148-3.631 5.986s-6.028-3.04-6.028-3.04c-2.514-.811-6.452 1.611-5.634 4.717 1.018 3.867 5.63 18.824 5.63 18.824 1.132 3.576 1.468 5.775 4.054 7.075 3.981 2 20.412-3.253 28.578-5.665 5.157-1.524 1.9-4.73 1.9-4.73s-2.871-1.737-8.915-4.965c-2.927-1.563-1.984-6.615 2.977-5.13l13.842 8.5c6.126 3.568 8.981-.539 8.981-.539 4.682-6.768 6.631-12.9 8.222-22.3 4.415-26.038-7.339-49.747-26.25-52.951Z"
            />
        </svg>
    )
}
