import { ReactComponent as IconClipboard } from 'assets/icons/icon-clipboard.svg'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import Panel from '../shared/panel'
import { Input, selectStyles } from '../shared/select-styles'
import ProfilePage from './profile-page'
import OauthAccounts from 'components/oauth/accounts'
import { DateTime } from 'luxon'

const Options = observer(() => {
    const { t } = useTranslation()
    const { user } = useMst()

    const ocrStatuses = [
        { value: 'never', label: t('web_options_ocr_activate_never') },
        { value: 'always', label: t('web_options_ocr_activate_always') },
        { value: 'manually', label: t('web_options_ocr_activate_manually') },
    ] as const

    const aiStatuses = [
        { value: 'never', label: t('web_options_ai_activate_never') },
        { value: 'always', label: t('web_options_ai_activate_always') },
    ] as const

    const [ocrStatus, setOcrStatus] = useState<(typeof ocrStatuses)[number]>(
        ocrStatuses.find(s => s.value === user.ocrStatus)
    )
    const [aiStatus, setAiStatus] = useState<(typeof aiStatuses)[number]>(
        aiStatuses.find(s => s.value === user.aiStatus)
    )
    const [validForwardEmails, setValidForwardEmails] = useState<string>(user.validForwardEmails)
    const [saving, setSaving] = useState<boolean>(false)

    const changeOcrStatus = useCallback(
        async (status: (typeof ocrStatuses)[number]) => {
            user.setOcrStatus(status.value)
            await user.update()
            setOcrStatus(status)
        },
        [ocrStatus]
    )
    const changeAiStatus = useCallback(
        async (status: (typeof aiStatuses)[number]) => {
            user.setAiStatus(status.value)
            await user.update()
            setAiStatus(status)
        },
        [aiStatus]
    )

    const saveUser = async () => {
        setSaving(true)
        user.setValidForwardEmails(validForwardEmails)
        await user.update()
        setSaving(false)

        toast('success', 'web_me_profile_saved')
    }

    return (
        <ProfilePage url="/me/options">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col md:w-2/3">
                    <div className="my-4">
                        <OauthAccounts />
                    </div>
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-lg">{t('web_me_options_ocr')}</h2>
                        <div className="prose flex w-full max-w-full flex-col items-center justify-center">
                            <div className="text-left">
                                <Trans i18nKey="web_ocr_new_desc">{t('web_ocr_new_desc')}</Trans>
                            </div>

                            <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                    {t('web_options_ocr_activate')}
                                </span>
                                <Select
                                    styles={selectStyles}
                                    components={{ Input }}
                                    className="w-full md:w-1/2"
                                    value={ocrStatus}
                                    placeholder={t('web_placeholder_select')}
                                    options={ocrStatuses}
                                    onChange={status => changeOcrStatus(status)}
                                />
                            </div>
                        </div>
                    </div>
                    {user.aiAvailableAt && user.aiAvailableAtDate < DateTime.now() && (
                        <div className="my-4">
                            <h2 className="font-bold mb-2 text-lg">{t('web_me_options_ai')}</h2>
                            <div className="prose flex w-full max-w-full flex-col items-center justify-center">
                                <div className="text-left">
                                    <Trans i18nKey="web_ai_new_desc">{t('web_ai_new_desc')}</Trans>
                                </div>

                                <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                    <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                        {t('web_options_ai_activate')}
                                    </span>
                                    <Select
                                        styles={selectStyles}
                                        components={{ Input }}
                                        className="w-full md:w-1/2"
                                        value={aiStatus}
                                        placeholder={t('web_placeholder_select')}
                                        options={aiStatuses}
                                        onChange={status => changeAiStatus(status)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-lg">{t('web_me_options_forward_email')}</h2>
                        <div className="prose flex w-full max-w-full flex-col items-center justify-center gap-4">
                            <div className="text-left">{t('web_me_options_forward_email_desc')}</div>

                            <span className="w-full pr-2">{t('web_me_options_forward_email_address')}</span>
                            <div className="flex w-full flex-col gap-2">
                                <div className="flex flex-row gap-2 not-prose px-12">
                                    <code className="grow rounded bg-geyser px-4 py-2 text-sm shadow-inner ">
                                        {user.forwardEmail}
                                    </code>
                                    <IconClipboard
                                        className="w-4 cursor-pointer fill-current text-regent-gray"
                                        onClick={async () => {
                                            await navigator.clipboard.writeText(user.forwardEmail)
                                            toast('success', 'web_me_options_forward_email_address_copied')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="text-left text-sm text-regent-gray">
                                <Trans i18nKey="web_me_options_forward_email_help">
                                    {t('web_me_options_forward_email_help')}
                                </Trans>
                            </div>
                            <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                <span className="w-full pr-2 text-regent-gray md:w-1/2 font-bold">
                                    {t('web_me_options_valid_forward_email')}
                                </span>
                                <textarea
                                    className="w-full md:w-1/2"
                                    value={validForwardEmails}
                                    disabled={saving || user.readOnly}
                                    onChange={e => setValidForwardEmails(e.currentTarget.value)}
                                />
                            </div>
                            <div className="text-left text-sm text-regent-gray">
                                <Trans i18nKey="web_me_options_valid_forward_email_help">
                                    {t('web_me_options_valid_forward_email_help')}
                                </Trans>
                            </div>
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full md:w-1/2" />
                            <button type="button" onClick={() => saveUser()} className="btn w-full md:w-1/2">
                                {t('web_me_profile_save')}
                            </button>
                        </div>
                    </div>
                </div>
            </Panel>
        </ProfilePage>
    )
})

export default Options
