import CryptoJS from 'crypto-js'
import { DateTime } from 'luxon'
import { flow, toJS } from 'mobx'
import makeInspectable from 'mobx-devtools-mst'
import { type Instance, destroy, onSnapshot, setLivelinessChecking, types } from 'mobx-state-tree'
import { createContext, useContext } from 'react'
import { _News } from 'stores/news/news'
import Config from '../core/config'
import { get, put } from '../core/services/http-service'
import { _Admin } from './admin'
import { _Brand } from './brands'
import { _CguStore } from './cgu'
import { _CmsPage } from './cms-page'
import { _CollaborationMessageStore } from './collaboration/messages'
import { _Config } from './config'
import { _ErrorManager } from './error-manager'
import { _Files } from './files'
import { _InformationMessageStore } from './information-messages'
import { _NewsStore } from './news'
import { _NotificationCenter } from './notifications'
import { _Partners } from './partners'
import { _PaymentManager } from './payment-manager'
import { _Search } from './search'
import { _ShareGroup } from './share-group'
import { _Telecollecte } from './telecollecte'
import { _Todo } from './todo'
import { _TrustedUserStore } from './trusted-user'
import { _UserTypeStore } from './user-type'
import { _User } from './users'

const shouldCrypt = (): boolean => {
    if (!process.env.REACT_APP_CRYPT_STORE) {
        return false
    }

    return process.env.REACT_APP_CRYPT_STORE === 'true'
}

const storageName = `${process.env.REACT_APP_NAME}_root`
const linkedAccountStorageName = `${process.env.REACT_APP_NAME}_linkedAccount`

const RootModel = types
    .model('Store', {
        user: types.optional(_User, {}),
        news: types.optional(_News, {}),
        collaborationMessageStore: types.optional(_CollaborationMessageStore, {}),
        impersonatingToken: '',
        impersonatingUser: types.optional(_User, {}),
        admin: types.optional(_Admin, {}),
        error: types.optional(_ErrorManager, {}),
        todo: types.optional(_Todo, {}),
        files: types.optional(_Files, {}),
        shareGroup: types.optional(_ShareGroup, {}),
        partners: types.optional(_Partners, {}),
        brand: types.optional(_Brand, {}),
        telecollecte: types.optional(_Telecollecte, {}),
        notificationCenter: types.optional(_NotificationCenter, {}),
        informationMessageStore: types.optional(_InformationMessageStore, {}),
        newsStore: types.optional(_NewsStore, {}),
        cguStore: types.optional(_CguStore, {}),
        trustedUserStore: types.optional(_TrustedUserStore, {}),
        userTypeStore: types.optional(_UserTypeStore, {}),
        token: '',
        identification: '',
        menuOpen: true,
        aiSidebarOpened: true,
        rememberMe: false,
        search: types.optional(_Search, {}),
        config: types.optional(_Config, {}),
        paymentManager: types.optional(_PaymentManager, {}),
        pages: types.map(_CmsPage),
        hasError: false,
        openedMenu: '',
    })
    .views(self => ({
        get impersonating(): boolean {
            return self.impersonatingToken !== ''
        },

        get isLogged() {
            return self.token !== '' && self.user.isLogged
        },
    }))
    .actions(self => ({
        errored() {
            self.hasError = true
        },

        cleanError() {
            self.hasError = false
        },

        setToken(token: string) {
            // add 2 hours for the cookie, but we are sure we will be disconnect before that
            const date = DateTime.local().plus({ hours: 2 })
            document.cookie = `Authentication=${token};expires=${date.toHTTP()}; path=/`
            self.token = token
        },

        reset() {
            this.setToken('')
            destroy(self.user)
            destroy(self.files)
            destroy(self.error)
            localStorage.removeItem(storageName)
        },

        getUserAccount() {
            let content = localStorage.getItem(linkedAccountStorageName)
            if (content === null) {
                return undefined
            }
            if (shouldCrypt()) {
                const bytes = CryptoJS.AES.decrypt(content, process.env.REACT_APP_JWT_SECRET)
                content = bytes.toString(CryptoJS.enc.Utf8)
            }
            if (content === null) {
                return undefined
            }
            const json = JSON.parse(content)
            localStorage.removeItem(linkedAccountStorageName)

            return json
        },

        storeUserAccount(account) {
            let json = JSON.stringify(account)
            if (shouldCrypt()) {
                const cryptedText = CryptoJS.AES.encrypt(json, process.env.REACT_APP_JWT_SECRET)
                json = cryptedText.toString()
            }
            localStorage.setItem(linkedAccountStorageName, json)
        },

        setImpersonatingToken(token: string) {
            self.impersonatingToken = token
        },

        setImpersonatingUser(user) {
            user.currentBrand = undefined
            user.currentFranchise = undefined
            self.impersonatingUser = user
        },

        logout(redirect?: string) {
            if (self.impersonating) {
                this.setToken(self.impersonatingToken)
                self.user.refresh(toJS(self.impersonatingUser))
            } else {
                this.setToken('')
                self.user.reset()

                document.cookie = 'Authentication=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
            }

            self.impersonatingToken = ''
            self.impersonatingUser.reset()

            window.location.href = redirect ?? '/'
        },

        setMenuOpen(menuOpen: boolean) {
            self.menuOpen = menuOpen
        },

        setAiSidebarOpen(open: boolean) {
            self.aiSidebarOpened = open
        },

        setRememberMe(rememberMe: boolean) {
            self.rememberMe = rememberMe
        },

        setCmsPages(pages) {
            self.pages.replace(pages)
        },

        setOpenedMenu(value: string) {
            self.openedMenu = value
        },
    }))
    .actions(self => ({
        getCmsPages: flow(function*(locale = 'fr') {
            self.error.clean()

            type JsonReturn = object

            try {
                const data = yield get<{ locale: string }, JsonReturn>('/v1/web/cms-pages', { locale })
                const {
                    data: { pages },
                } = data
                self.setCmsPages(pages)
            } catch (err) {
                self.error.prepare(err)
            }
        }),

        saveCmsPage: flow(function*(name: string, content: string, locale = 'fr') {
            self.error.clean()

            type JsonReturn = object

            try {
                const putData = { name, content }
                const data = yield put<typeof putData, JsonReturn>(`/v1/web/cms-pages?locale=${locale}`, putData)
                const {
                    data: { pages },
                } = data
                self.setCmsPages(pages)
            } catch (err) {
                self.error.prepare(err)
            }
        }),

        findCity: flow(function*(q: string) {
            self.error.clean()

            type JsonReturnContent = object
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const getData = { q }
                const data = yield get<typeof getData, JsonReturn>('/v1/web/cities', getData)

                const {
                    data: { cities },
                } = data

                return cities
            } catch (err) {
                self.error.prepare(err)
            }
        }),

        ping: async () => {
            try {
                const response = await fetch(`${Config.app.APIURL}/v1/ping`)

                return response.status === 204
            } catch (err) {
                return false
            }
        },
    }))

const getFromStorage = () => {
    try {
        let content = localStorage.getItem(storageName)
        if (content === null) {
            return undefined
        }
        if (shouldCrypt()) {
            const bytes = CryptoJS.AES.decrypt(content, process.env.REACT_APP_JWT_SECRET)
            content = bytes.toString(CryptoJS.enc.Utf8)
        }
        if (content === null) {
            return undefined
        }
        const json = JSON.parse(content)

        if (!json.identification) {
            json.identification = crypto.randomUUID()
        }

        return json
    } catch (err) {
        console.error('data json', err)

        return undefined
    }
}

const initialState = getFromStorage() ?? {
    user: {},
    config: {},
    menuOpen: true,
    openedMenu: 'dataroom',
    identification: crypto.randomUUID(),
}

export const rootStore = RootModel.create(initialState)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface RootInstance extends Instance<typeof RootModel> { }

setLivelinessChecking('ignore')
makeInspectable(rootStore)

onSnapshot(rootStore, snapshot => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { user, menuOpen, token, rememberMe, config, openedMenu } = snapshot

    const json = JSON.stringify({
        user,
        menuOpen,
        token,
        rememberMe,
        config,
        openedMenu,
    })
    let content = json

    if (shouldCrypt()) {
        const cryptedText = CryptoJS.AES.encrypt(json, process.env.REACT_APP_JWT_SECRET)
        content = cryptedText.toString()
    }

    localStorage.setItem(storageName, content)
})

const RootStoreContext = createContext<null | RootInstance>(null)

export const Provider = RootStoreContext.Provider
export const useMst = (): RootInstance => {
    const store = useContext(RootStoreContext)
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider')
    }

    return store
}
