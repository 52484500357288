import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type CSSProperties, type ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import Tooltip from './tooltip'
import { motion } from 'framer-motion'
import { cn } from 'core/utils/cn'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'

type Props = {
    disabled?: boolean
    image?: ReactNode
    className?: string
    buttonClassName?: string
    children?: ReactNode
    layoutId?: string
    menuStyle?: CSSProperties
    notify?: boolean
    notifyCount?: number
    onClick?: () => void
    tooltip?: ReactNode | string
}

function DivButton({ notifyCount = 0, notify = false, image }: Props) {
    return (
        <>
            {notify && (
                <div
                    className="absolute h-3 w-3 animate-ping rounded-full bg-christine"
                    style={{ right: '.7em', top: '.7em' }}
                />
            )}
            {notifyCount > 0 && (
                <div
                    className="absolute h-4 rounded-full bg-christine px-1 text-xs text-white"
                    style={{ left: '2.1em', top: '.6em' }}
                >
                    {notifyCount}
                </div>
            )}
            {image}
        </>
    )
}

export default function ButtonImage({
    disabled = false,
    tooltip,
    className = 'relative inline-block mx-2',
    buttonClassName,
    notifyCount = 0,
    notify = false,
    image,
    children,
    menuStyle = {},
    onClick,
    layoutId,
}: Props) {
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement>()
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-end',
    })

    return (
        <Tooltip tooltip={tooltip}>
            <motion.div className={className} onClick={onClick} layoutId={layoutId}>
                {children ? (
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                                <Popover.Button
                                    ref={setReferenceElement}
                                    className={cn(
                                        'relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-50 bg-white shadow-lg',
                                        'disabled:bg-gray-300 disabled:shadow-none',
                                        buttonClassName
                                    )}
                                >
                                    <DivButton
                                        disabled={disabled}
                                        buttonClassName={cn('cursor-pointer ', buttonClassName)}
                                        notify={notify}
                                        notifyCount={notifyCount}
                                        image={image}
                                    />

                                    <div className="absolute bottom-0 right-0 shadow-md rounded-full bg-white size-4 flex items-center justify-center">
                                        <FontAwesomeIcon
                                            icon={faCaretDown}
                                            className="fill-current"
                                            rotation={open ? 180 : undefined}
                                            size="xs"
                                        />
                                    </div>
                                </Popover.Button>
                                <Popover.Panel
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                    className="absolute z-50 rounded bg-white p-4 shadow-lg mt-2"
                                >
                                    <div className="flex flex-col rounded text-left text-base">{children}</div>
                                </Popover.Panel>
                            </>
                        )}
                    </Popover>
                ) : (
                    <button
                        disabled={disabled}
                        type="button"
                        className={cn(
                            'relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-50 bg-white shadow-lg',
                            'disabled:bg-gray-300 disabled:shadow-none',
                            buttonClassName
                        )}
                    >
                        <DivButton
                            disabled={disabled}
                            buttonClassName={buttonClassName}
                            notify={notify}
                            notifyCount={notifyCount}
                            image={image}
                        />
                    </button>
                )}
            </motion.div>
        </Tooltip>
    )
}
