import { faArrowUp, faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from 'core/utils/cn'
import { type FormEvent, useEffect, useRef, type ChangeEvent } from 'react'

function parseValue(v: string) {
    return v.endsWith('px') ? Number.parseInt(v.slice(0, -2), 10) : 0
}

function calculateNumLines(str: string, textarea: HTMLTextAreaElement) {
    const textareaStyles = window.getComputedStyle(textarea)
    const font = `${textareaStyles.fontSize} ${textareaStyles.fontFamily}`
    const paddingLeft = parseValue(textareaStyles.paddingLeft)
    const paddingRight = parseValue(textareaStyles.paddingRight)
    const textareaWidth = textarea.getBoundingClientRect().width - paddingLeft - paddingRight

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
        return 0
    }
    context.font = font

    const words = str.split(' ')
    let lineCount = 0
    let currentLine = ''
    for (let i = 0; i < words.length; i++) {
        const wordWidth = context.measureText(`${words[i]} `).width
        const lineWidth = context.measureText(currentLine).width

        if (lineWidth + wordWidth > textareaWidth) {
            lineCount++
            currentLine = `${words[i]} `
        } else {
            currentLine += `${words[i]} `
        }
    }

    if (currentLine.trim() !== '') {
        lineCount++
    }

    return lineCount
}

export default function Textarea({
    input,
    placeholder,
    handleInputChange,
    disabled,
    submit,
}: {
    input: string
    placeholder: string
    handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
    disabled: boolean
    submit: (e: FormEvent<HTMLFormElement>) => void
}) {
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const sendRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (!disabled) {
            inputRef.current?.focus()
        }
    }, [disabled])

    useEffect(() => {
        if (!inputRef.current) {
            return
        }
        if (
            typeof input === 'string' &&
            (calculateNumLines(input, inputRef.current) >= 2 || input.split(/\n|\r/).length > 1)
        ) {
            inputRef.current.style.height = 'auto'
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`

            return
        }
        inputRef.current.style.height = '44px'
    }, [input])

    return (
        <div className="flex gap-4 items-center justify-center px-4 pb-8">
            <form
                className="w-full max-w-3xl bg-white rounded-xl flex items-center p-4 relative border border-geyser"
                onSubmit={submit}
            >
                <textarea
                    ref={inputRef}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={cn(
                        'flex min-h-[60px] w-full bg-transparent px-3 py-2 border-none',
                        'text-sm placeholder:text-gray-chateau focus-visible:outline-none focus-visible:ring-0',
                        'disabled:cursor-not-allowed disabled:opacity-50'
                    )}
                    style={{ resize: 'none', height: 44 }}
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault()
                            sendRef.current?.click()
                        }
                    }}
                />

                <button
                    disabled={disabled || !input || input === ''}
                    type="submit"
                    ref={sendRef}
                    className="text-white bg-atomic-tangerine absolute right-[2rem] bottom-1/2 translate-y-1/2 w-[53px] h-[53px] disabled:text-muted rounded-full disabled:bg-geyser"
                >
                    <FontAwesomeIcon icon={faArrowUp} className="w-[15px] h-[15px]" />
                </button>
            </form>
        </div>
    )
}
