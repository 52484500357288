import i18next from 'i18next'
// import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'
import Config from './config'

export const availableLocales = ['fr', 'en'] as const
export type Locale = typeof availableLocales[number]

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'fr',
        supportedLngs: availableLocales,
        lng: 'fr',
        debug: false,
        backend: {
            backends: [
                //LocalStorageBackend,
                HttpApi,
            ],
            backendOptions: [
                // {
                //     prefix: process.env.REACT_APP_NAME ? `${process.env.REACT_APP_NAME}_` : 'i18next_res_',
                //     expirationTime: Config.i18n.EXPIRATION_TIME,
                //     defaultVersion: process.env.REACT_APP_BUILD_DATE ?? '1.0',
                // },
                {
                    loadPath: `${Config.app.APIURL}/v1/messages/{{lng}}?prefix[]=web&prefix[]=api`,
                    crossDomain: true,
                    customHeaders: () => ({
                        'X-API-KEY': Config.app.APIKEY,
                        'X-APP-VERSION': Config.app.version.NUMBER,
                    }),
                    parse: data => {
                        const json = JSON.parse(data)
                        const {
                            data: { messages },
                        } = json

                        return messages
                    },
                },
            ],
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'li'],
        },
        detection: {
            order: ['querystring', 'cookie'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
        },
    })

i18next.on('languageChanged', lng => {
    const html = document.querySelector<HTMLElement>('html')
    if (html) {
        html.setAttribute('lang', lng)
    }

    Settings.defaultLocale = lng
})

export default i18next
