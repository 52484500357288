import { faPlug, faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as IconBurger } from 'assets/icons/icon-burger.svg'
import iconCgu from 'assets/icons/icon-cgu.svg'
import { ReactComponent as IconDataroom } from 'assets/icons/icon-dataroom.svg'
import iconFaq from 'assets/icons/icon-faq.svg'
import iconHelp from 'assets/icons/icon-help.svg'
import { ReactComponent as IconHome } from 'assets/icons/icon-home.svg'
// import { ReactComponent as Plus } from 'assets/plus.svg'
// import { ReactComponent as ArrowDown } from 'assets/arrow/arrow-down.svg'
// import { ReactComponent as ArrowUp } from 'assets/arrow/arrow-up.svg'
// import { ReactComponent as IconAdd } from 'assets/icons/icon-add-file.svg'
import iconLogout from 'assets/icons/icon-logout.svg'
import iconNotifications from 'assets/icons/icon-notifications.svg'
import iconPermissions from 'assets/icons/icon-permissions.svg'
import iconPrivacy from 'assets/icons/icon-privacy.svg'
import iconRadar from 'assets/icons/icon-radar.svg'
import { ReactComponent as IconShare } from 'assets/icons/icon-share.svg'
import iconSurvey from 'assets/icons/icon-survey.svg'
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg'
// import { ReactComponent as IconShared } from 'assets/icons/icon-shared.svg'
// import { ReactComponent as IconHistoric } from 'assets/icons/icon-historic.svg'
// import { ReactComponent as IconQuarantine } from 'assets/icons/icon-quarantine.svg'
import { ReactComponent as IconWhoWeAre } from 'assets/icons/icon-who-we-are.svg'
import { ReactComponent as Plus } from 'assets/plus.svg'
import { clsx } from 'clsx'
import { off, on } from 'core/events'
import { availableLocales } from 'core/i18n'
import { cn } from 'core/utils/cn'
import { DateTime, type ToRelativeUnit } from 'luxon'
import { observer } from 'mobx-react-lite'
import { type CSSProperties, type ReactNode, createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { hasBrandAccess } from 'stores/brands'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import CrispButton from '../crisp-button'
import ButtonImage from '../shared/button-image'
import InformationBar from '../shared/information-bar'
import { MenuDivider, MenuItem, SubMenu, SubMenuOpen } from '../shared/menu'
import Modal from '../shared/modal'
import ModelConfig from '../telecollecte/model-config'
import SlidingConnectors from '../telecollecte/sliding-connectors'
import InformationMessages from './information-messages'
import Menu from './menu'
import NotificationBell from './notification-bell'
import Search from './search'
import AddTrustedPerson from './sharing/add-trusted-person'
import FindPartner from './sharing/find-partner'
import Uploader from './uploader'
import Image from './user-image'

const Impersonating = observer(() => {
    const { t } = useTranslation()
    const { isLogged, impersonating, user, logout } = useMst()

    if (!isLogged) {
        return null
    }

    if (!impersonating) {
        return null
    }

    return (
        <InformationBar
            color="#F15F12"
            message={t('web_user_impersonating', { user: user.fullname, id: user.id })}
            buttonMessage={t('web_user_impersonating_logout')}
            onClick={() => logout()}
        />
    )
})

const UserDeleted = observer(() => {
    const { t } = useTranslation()
    const { isLogged, user } = useMst()

    if (!isLogged) {
        return null
    }

    if (!user.deleted) {
        return null
    }

    if ((user.isReadOnly && user.accountDeleteAt === 0) || (user.isReadOnly && user.accountBlockAt === 0)) {
        return null
    }

    const date = user.isReadOnly ? user.deleteAt : user.blockAt

    const navigate = useNavigate()

    return (
        <InformationBar
            color="#F15F12"
            message={t(user.isReadOnly ? 'web_user_account_blocked' : 'web_user_account_deleted', {
                time: date.toRelative(),
                date: date.toLocaleString(DateTime.DATE_SHORT),
            })}
            buttonMessage={t('web_user_cancel_deletion')}
            onClick={() => navigate('/me/prices')}
        />
    )
})

const UserReadOnly = observer(() => {
    const { t } = useTranslation()
    const { isLogged, user } = useMst()

    if (!isLogged) {
        return null
    }

    if (!user.readOnly) {
        return null
    }

    let message: string
    if (user.canManagePersonalData) {
        const date = user.personalDataAccessEndsAt
        const options: { unit: ToRelativeUnit[] } = { unit: ['days', 'hours', 'minutes', 'seconds'] }

        message = t('web_user_account_read_only', { brand: 'test', time: date.toRelative(options) })
    } else {
        message = t('web_user_account_read_only_blocked', { brand: 'test' })
    }

    const createAccount = async () => {
        await user.prepareLinkAccount()
    }

    return (
        <InformationBar
            color="#933489"
            message={message}
            buttonMessage={t('web_user_account_read_only_create_account')}
            onClick={() => createAccount()}
        />
    )
})

const UserNotVerified = observer(() => {
    const { t } = useTranslation()
    const { isLogged, user } = useMst()

    if (!isLogged) {
        return null
    }

    if (user.checked || user.timeBeforeDeactivation < 0) {
        return null
    }

    const date = DateTime.local().plus({ minutes: user.timeBeforeDeactivation })
    const options: { unit: ToRelativeUnit[] } = { unit: ['hours', 'minutes', 'seconds'] }

    const resendMail = async () => {
        await user.resendVerifyEmailToken()
    }

    return (
        <InformationBar
            color="#F15F12"
            message={t('web_user_email_not_validated', {
                time: date.toRelative(options),
            })}
            buttonMessage={t('web_user_email_not_validated_resend')}
            onClick={() => resendMail()}
        />
    )
})

interface Props {
    title?: ReactNode | string
    children?: ReactNode
    containerClassnames?: string
    showConfigOpened?: boolean
    onShowConfigClicked?: (value: boolean) => void
    space?: Space
    canDrag?: boolean
    showSearch?: boolean
    leftComponent?: ReactNode
}

const ContentPage = observer(
    ({
        children,
        title = '',
        showConfigOpened = false,
        containerClassnames = '',
        space,
        onShowConfigClicked,
        canDrag = true,
        showSearch = true,
        leftComponent,
    }: Props) => {
        const navigate = useNavigate()
        const rootStore = useMst()
        const { user, menuOpen, isLogged } = rootStore

        const location = useLocation()

        useEffect(() => {
            rootStore.error.clean()
        }, [location])

        const { t } = useTranslation()
        const inputRef = createRef<HTMLInputElement>()

        const [accountMenu, setAccountMenu] = useState<number>(0)
        const initialMenuStyle = { minWidth: '16rem' }
        const [menuStyle, setMenuStyle] = useState<CSSProperties>(initialMenuStyle)
        const [showAddTrustedPersonModal, setShowAddTrustedPersonModal] = useState<boolean>(false)
        const [showFindPartnerModal, setshowFindPartnerModal] = useState<boolean>(false)

        const prepareLinkAccount = async () => await user.prepareLinkAccount()
        const connectAnother = async (uuid: string) => {
            await user.reconnect(uuid)
            window.location.reload()
        }
        const removeAccount = async (uuid: string) => {
            await user.removeLinkAccount(uuid)
        }

        const onAddFileClicked = () => {
            if (inputRef.current) {
                inputRef.current.click()
            }
        }

        const useFranchise = async (uuid: string) => {
            await user.useFranchise(uuid)
        }

        const onAddFindPartnerClicked = () => setshowFindPartnerModal(true)
        const onAddTrustedPersonClicked = () => setShowAddTrustedPersonModal(true)

        const onModelClicked = (value: boolean) => {
            if (onShowConfigClicked) {
                onShowConfigClicked(value)
            } else {
                navigate('/dataroom?show-widget=true')
            }
        }

        useEffect(() => {
            on('uploader:trigger', () => onAddFileClicked())

            return () => off('uploader:trigger', () => onAddFileClicked())
        })

        return (
            <>
                <Impersonating />
                <UserDeleted />
                <UserNotVerified />
                <UserReadOnly />

                {isLogged && <InformationMessages />}
                <div className="bg-athens-gray relative flex h-full min-h-screen max-w-full flex-row overflow-x-hidden">
                    <Uploader ref={inputRef} space={space} canDrag={canDrag} />

                    <Menu
                        onAddFileClicked={onAddFileClicked}
                        onAddFindPartnerClicked={onAddFindPartnerClicked}
                        onAddTrustedPersonClicked={onAddTrustedPersonClicked}
                    />

                    {leftComponent}

                    <div className={clsx('flex flex-col p-4 pl-6 transition-all duration-500 ease-in-out grow')}>
                        <div className="flex flex-col justify-between items-start gap-y-4 p-2 pt-4 md:flex-row print:hidden">
                            <div className="flex w-full items-center justify-between md:w-2/3 md:justify-start">
                                {typeof title === 'string' ? (
                                    <h1 className="mr-12 text-2xl font-bold">{title}</h1>
                                ) : (
                                    title
                                )}
                                {isLogged && showSearch && <Search />}
                            </div>

                            {isLogged && (
                                <div className="flex items-center justify-between md:justify-start">
                                    {hasBrandAccess(user, 'show_models') && (
                                        <ModelConfig opened={showConfigOpened} onClicked={onModelClicked} />
                                    )}
                                    {hasBrandAccess(user, 'show_telecollecte') && <SlidingConnectors />}

                                    <NotificationBell />

                                    <ButtonImage
                                        tooltip={t('web_tooltips_profile')}
                                        image={
                                            <Image
                                                className="rounded-50 size-12 shrink-0 overflow-hidden bg-contain"
                                                altClassName="p-3"
                                                src={user.picture}
                                                alt={t('web_tooltips_profile')}
                                            />
                                        }
                                        menuStyle={menuStyle}
                                    >
                                        {accountMenu === 0 && (
                                            <>
                                                <MenuItem
                                                    to="/me"
                                                    icon={
                                                        <Image
                                                            src={user.picture}
                                                            className="rounded-50 mr-2 size-6 shrink-0 overflow-hidden"
                                                            altClassName="border border-geyser text-geyser"
                                                            alt={t('web_menu_my_profile')}
                                                        />
                                                    }
                                                >
                                                    {t('web_menu_my_profile')}
                                                </MenuItem>
                                                <MenuDivider />
                                                {user.account.accounts
                                                    .filter(account => account.uuid !== user.uuid)
                                                    .map(account => (
                                                        <div
                                                            key={account.uuid}
                                                            className={
                                                                'text-regent-gray inline-flex items-center gap-2 px-4 py-2'
                                                            }
                                                        >
                                                            <Image
                                                                src={account.picture}
                                                                className="mr-1 size-5 shrink-0 overflow-hidden rounded-full"
                                                                altClassName="border border-geyser text-geyser"
                                                                alt={t('web_menu_cgu')}
                                                            />
                                                            <div className="flex flex-col">
                                                                <div
                                                                    className="flex cursor-pointer flex-col items-start justify-center "
                                                                    onClick={() => connectAnother(account.uuid)}
                                                                >
                                                                    <span>{account.fullname}</span>
                                                                    <span className="text-geyser text-sm">
                                                                        ({account.email})
                                                                    </span>
                                                                </div>
                                                                <span
                                                                    onClick={() => removeAccount(account.uuid)}
                                                                    className="text-bittersweet cursor-pointer text-xs"
                                                                >
                                                                    {t('web_dataroom_remove_account')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                <div
                                                    onClick={() => prepareLinkAccount()}
                                                    className="text-regent-gray inline-flex cursor-pointer items-center px-4 py-2"
                                                >
                                                    <Plus
                                                        title={t('web_dataroom_add_account')}
                                                        className={clsx(
                                                            menuOpen ? 'mr-4' : '',
                                                            'text-christine stroke-current'
                                                        )}
                                                    />
                                                    <span className="">{t('web_dataroom_add_account')}</span>
                                                </div>
                                                <MenuDivider />
                                                {user.franchises.length > 0 && (
                                                    <>
                                                        {user.franchises
                                                            .filter(franchise =>
                                                                ['FRANCHISEE', 'AGENT'].includes(franchise.role)
                                                            )
                                                            .map(franchise => (
                                                                <div
                                                                    key={franchise.uuid}
                                                                    className={clsx(
                                                                        'text-regent-gray inline-flex items-center gap-2 px-4 py-2',
                                                                        franchise.uuid === user.currentFranchise.uuid &&
                                                                            'bg-atomic-tangerine/25'
                                                                    )}
                                                                >
                                                                    <Image
                                                                        src={franchise.icon}
                                                                        className="mr-1 size-5 shrink-0 overflow-hidden rounded-full"
                                                                        altClassName="border border-geyser text-geyser"
                                                                        alt={franchise.name}
                                                                    />
                                                                    <div className="flex flex-col">
                                                                        <div
                                                                            className="flex cursor-pointer flex-col items-start justify-center "
                                                                            onClick={() => useFranchise(franchise.uuid)}
                                                                        >
                                                                            <span>{franchise.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        <MenuDivider />
                                                    </>
                                                )}
                                                <SubMenu
                                                    icon={
                                                        <img
                                                            src={iconPermissions}
                                                            className="mr-2"
                                                            alt={t('web_menu_permissions')}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        setAccountMenu(1)
                                                        setMenuStyle({ minWidth: '20rem' })
                                                    }}
                                                >
                                                    {t('web_menu_permissions')}
                                                </SubMenu>
                                                <MenuDivider />
                                                <MenuItem
                                                    to="https://treasy.fr/conditions-generales-dutilisation/"
                                                    external={true}
                                                    icon={
                                                        <img src={iconCgu} className="mr-2" alt={t('web_menu_cgu')} />
                                                    }
                                                >
                                                    {t('web_menu_cgu')}
                                                </MenuItem>
                                                <MenuItem
                                                    to="https://treasy.fr/foire-aux-questions/"
                                                    external={true}
                                                    icon={
                                                        <img src={iconFaq} className="mr-2" alt={t('web_menu_faq')} />
                                                    }
                                                >
                                                    {t('web_menu_faq')}
                                                </MenuItem>
                                                <SubMenu
                                                    icon={
                                                        <img src={iconHelp} className="mr-2" alt={t('web_menu_help')} />
                                                    }
                                                    onClick={() => {
                                                        setAccountMenu(2)
                                                        setMenuStyle({ minWidth: '20rem' })
                                                    }}
                                                >
                                                    {t('web_menu_help')}
                                                </SubMenu>
                                                <MenuItem
                                                    to="https://treasy.fr/qui-sommes-nous"
                                                    external={true}
                                                    icon={
                                                        <IconWhoWeAre
                                                            className="mr-2 inline-block fill-current"
                                                            title={t('web_link_who_we_are')}
                                                        />
                                                    }
                                                >
                                                    {t('web_link_who_we_are')}
                                                </MenuItem>
                                                <MenuDivider />
                                                <div
                                                    className={
                                                        'divide-geyser text-regent-gray inline-flex items-center divide-x px-4 py-2'
                                                    }
                                                >
                                                    {availableLocales.map(locale => (
                                                        <button
                                                            key={locale}
                                                            className="px-2"
                                                            onClick={() => {
                                                                user.setLocale(locale)
                                                                void user.update()
                                                            }}
                                                        >
                                                            {locale.toUpperCase()}
                                                        </button>
                                                    ))}
                                                </div>
                                                <MenuDivider />
                                                <MenuItem
                                                    to="/logout"
                                                    icon={
                                                        <img
                                                            src={iconLogout}
                                                            className="mr-2"
                                                            alt={t('web_menu_logout')}
                                                        />
                                                    }
                                                >
                                                    {t('web_menu_logout')}
                                                </MenuItem>
                                            </>
                                        )}
                                        {accountMenu === 1 && (
                                            <>
                                                <SubMenuOpen
                                                    icon={
                                                        <img
                                                            src={iconPermissions}
                                                            className="mr-2"
                                                            alt={t('web_menu_permissions')}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        setAccountMenu(0)
                                                        setMenuStyle(initialMenuStyle)
                                                    }}
                                                >
                                                    {t('web_menu_permissions')}
                                                </SubMenuOpen>
                                                <MenuItem
                                                    to="https://treasy.fr/politique-de-confidentialite/"
                                                    external={true}
                                                    icon={
                                                        <img
                                                            src={iconPrivacy}
                                                            className="mr-2"
                                                            alt={t('web_menu_privacy')}
                                                        />
                                                    }
                                                >
                                                    {t('web_menu_privacy')}
                                                </MenuItem>
                                                <MenuItem
                                                    to="/me/notifications/settings"
                                                    icon={
                                                        <img
                                                            src={iconNotifications}
                                                            className="mr-2"
                                                            alt={t('web_menu_notification_settings')}
                                                        />
                                                    }
                                                >
                                                    {t('web_menu_notification_settings')}
                                                </MenuItem>
                                            </>
                                        )}
                                        {accountMenu === 2 && (
                                            <>
                                                <SubMenuOpen
                                                    icon={
                                                        <img src={iconHelp} className="mr-2" alt={t('web_menu_help')} />
                                                    }
                                                    onClick={() => {
                                                        setAccountMenu(0)
                                                        setMenuStyle(initialMenuStyle)
                                                    }}
                                                >
                                                    {t('web_menu_help')}
                                                </SubMenuOpen>
                                                <MenuItem
                                                    to="assistance@treasy.fr"
                                                    email={true}
                                                    icon={
                                                        <img
                                                            src={iconRadar}
                                                            className="mr-2"
                                                            alt={t('web_menu_radar')}
                                                        />
                                                    }
                                                >
                                                    {t('web_menu_radar')}
                                                </MenuItem>
                                                <MenuItem
                                                    to="assistance@treasy.fr"
                                                    email={true}
                                                    icon={
                                                        <img
                                                            src={iconSurvey}
                                                            className="mr-2"
                                                            alt={t('web_menu_survey')}
                                                        />
                                                    }
                                                >
                                                    {t('web_menu_survey')}
                                                </MenuItem>
                                            </>
                                        )}
                                    </ButtonImage>

                                    {/* BEGIN Mobile menu */}
                                    <ButtonImage
                                        className="relative mx-2 inline-block md:hidden"
                                        image={<IconBurger className="size-6" />}
                                        menuStyle={menuStyle}
                                    >
                                        <MenuItem
                                            to="/dashboard"
                                            icon={
                                                <IconHome
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_dashboard')}
                                                />
                                            }
                                        >
                                            {t('web_link_dashboard')}
                                        </MenuItem>
                                        <MenuItem
                                            to="/dataroom"
                                            icon={
                                                <IconDataroom
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_dataroom')}
                                                />
                                            }
                                        >
                                            {t('web_link_dataroom')}
                                        </MenuItem>
                                        <MenuItem
                                            to="/dataroom/telecollecte"
                                            icon={<FontAwesomeIcon icon={faPlug} className="mr-2" />}
                                        >
                                            {t('web_link_telecollecte')}
                                        </MenuItem>
                                        <MenuItem
                                            to="/collaboration"
                                            icon={<FontAwesomeIcon icon={faUsers} className="mr-2" />}
                                        >
                                            {t('web_link_collaboration')}
                                        </MenuItem>
                                        <MenuItem
                                            to="/dataroom/trash"
                                            icon={
                                                <IconTrash
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_trash')}
                                                />
                                            }
                                        >
                                            {t('web_link_trash')}
                                        </MenuItem>
                                        <MenuItem
                                            to="/share"
                                            icon={
                                                <IconShare
                                                    className="mr-2 inline-block fill-current"
                                                    title={t('web_link_share')}
                                                />
                                            }
                                        >
                                            {t('web_link_share')}
                                        </MenuItem>
                                    </ButtonImage>
                                    {/* END Mobile menu */}
                                </div>
                            )}
                        </div>

                        <div
                            className={cn(
                                'relative flex py-10 print:p-0 flex-col h-[-webkit-fill-available]',
                                containerClassnames
                            )}
                        >
                            {children}

                            <Modal
                                size="1/2"
                                isOpen={showAddTrustedPersonModal}
                                onRequestClose={() => setShowAddTrustedPersonModal(false)}
                            >
                                <AddTrustedPerson
                                    onAdd={() => navigate('/me/friends')}
                                    onCancel={() => setShowAddTrustedPersonModal(false)}
                                />
                            </Modal>

                            <Modal
                                size="1/2"
                                isOpen={showFindPartnerModal}
                                onRequestClose={() => setshowFindPartnerModal(false)}
                            >
                                <FindPartner
                                    onAdd={() => navigate('/me/friends')}
                                    onCancel={() => setshowFindPartnerModal(false)}
                                />
                            </Modal>
                        </div>
                    </div>
                </div>

                {hasBrandAccess(user, 'show_crisp_button') && <CrispButton />}
            </>
        )
    }
)

export default ContentPage
