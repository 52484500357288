import { rootStore } from '../../stores/store'
import Config from '../config'

export class HttpError extends Error {
    code: number
    originalData: unknown

    constructor(message: string, code: number, originalData: unknown) {
        super(message)
        this.code = code
        this.originalData = originalData
    }
}

type HttpMethod = 'post' | 'put' | 'get' | 'delete' | 'head' | 'options' | 'patch'

const getFetch = async <T = unknown>(method: HttpMethod, uri: string, requestOptions = {}): Promise<T> => {
    if (!window.fetch) {
        throw new Error(
            "Votre navigateur est trop ancien pour supporter l'application, veuillez utiliser un navigateur à jour."
        )
    }

    const headers = new Headers({
        'Content-Type': 'application/json',
        'X-API-KEY': Config.app.APIKEY ?? '',
        'X-APP-VERSION': `${Config.app.version.NUMBER}`,
    })
    if (['post', 'put'].includes(method) && requestOptions instanceof FormData) {
        headers.delete('Content-Type')
    }

    if (rootStore.token) {
        headers.append('Authorization', `Bearer ${rootStore.token}`)
    }

    const options: { method: HttpMethod; headers: Headers; body?: string | FormData } = {
        method,
        headers,
    }
    if (requestOptions && ['post', 'put'].includes(method)) {
        if (requestOptions instanceof FormData) {
            options.body = requestOptions
        } else if (Object.keys(requestOptions).length > 0) {
            options.body = JSON.stringify(requestOptions)
        }
    }

    const url = new URL(uri, Config.app.APIURL)

    if (['get', 'options', 'head'].includes(method) && Object.keys(requestOptions).length > 0) {
        for (const key of Object.keys(requestOptions)) {
            const value = requestOptions[key]
            if (value === undefined) {
                continue
            }
            if (Array.isArray(value)) {
                for (const item of value) {
                    url.searchParams.append(`${key}[]`, item)
                }
            } else {
                url.searchParams.append(key, value)
            }
        }
    }
    if (rootStore.user?.currentFranchise?.uuid) {
        url.searchParams.append('cf', rootStore.user?.currentFranchise?.uuid)
    }

    const response = await fetch(url.toString(), options)
    if (response.ok) {
        if (response.status === 204) {
            return {} as T
        }

        const data = await response.json()

        return data
    }

    const { status, statusText } = response

    switch (status) {
        case 401:
            rootStore.logout()

            break
        case 404:
            throw new HttpError(statusText, status, undefined)

        default: {
            const data = await response.json()
            let message = Config.http[`CODE_${status}`]
            if (data) {
                const {
                    data: { errors },
                } = data
                const [error] = errors
                if (error) {
                    message = error
                }
            }

            throw new HttpError(message, status, data)
        }
    }

    return {} as T
}

export const get = async <T = never, U = unknown>(uri: string, params: T | undefined = undefined): Promise<U> =>
    await getFetch<U>('get', uri, params)

export const del = async <U = unknown>(uri: string): Promise<U> => await getFetch<U>('delete', uri)

export const post = async <T = never, U = unknown>(uri: string, data: T | undefined = undefined): Promise<U> =>
    await getFetch<U>('post', uri, data)

export const put = async <T = never, U = unknown>(uri: string, data: T | undefined = undefined): Promise<U> =>
    await getFetch<U>('put', uri, data)

export const postFile = async <U>(uri: string, data: FormData): Promise<U> => await getFetch<U>('post', uri, data)
