import * as Sentry from '@sentry/react'
import { DateTime } from 'luxon'
import React from 'react'
import ReactDOM from 'react-dom/client'
import packageInfo from '../package.json'
import App from './app'
import './core/i18n'
import './index.css'
import * as serviceWorker from './serviceWorker'

Sentry.init({
    dsn: 'https://7ef442378f912d63f0ee7d0761079712@o4507141400494080.ingest.de.sentry.io/4507141487853648',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/*treasy*/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled:
        process.env.REACT_APP_USE_SENTRY === 'true' ||
        (process.env.REACT_APP_USE_SENTRY as unknown as boolean) === true,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

const darkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches ?? false
const color = darkMode ? 'white' : 'rgb(25, 55, 74)'
const buildDate = Number.parseInt(process.env.REACT_APP_BUILD_DATE)
const date = typeof buildDate === 'number' ? DateTime.fromMillis(buildDate) : DateTime.local()

console.log(
    `%c${packageInfo.name} v${packageInfo.version} (build: ${date.toISO()}) - Made with 🍺 by ØPP Startup Studio`,
    `background: transparent; color: ${color}; font-size: 18px`
)
