import type { SpaceFile } from 'stores/files/file'
import FileIcon from '../upload/file-icon'
import { useState } from 'react'
import { cn } from 'core/utils/cn'

export default function FileImage({
    file,
    filename,
    className,
}: { file: SpaceFile; filename: string; className?: string }) {
    const [hasImage, setHasImage] = useState<boolean>()

    if (!hasImage) {
        return <FileIcon extension={file.extension} className={cn('w-5 shrink-0 grow-0', className)} />
    }

    return (
        <img
            src={file.imageRepresentation(25)}
            loading="lazy"
            alt={filename}
            className={cn('max-h-[1.25rem] w-5 max-w-[1.25rem] shrink-0 grow-0', className)}
            onError={() => setHasImage(false)}
        />
    )
}
