import { useChat } from 'ai/react'
import { faCheck, faArrowDown, faSpinnerThird, faSidebar, faWandSparkles } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Textarea from 'components/ai/textarea'
import ContentPage from 'components/dataroom/content-page'
import { continueConversation, type Answer } from 'core/conversation'
import { get, post } from 'core/services/http-service'
import { cn } from 'core/utils/cn'
import { iterateStreamResponse } from 'core/utils/streamable'
import { AnimatePresence, motion } from 'framer-motion'
import { type FormEvent, useState, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { StickToBottom, useStickToBottomContext } from 'use-stick-to-bottom'
import { useMst } from 'stores/store'
import { ReactComponent as Logo } from 'assets/logo.svg'
import Treasy from 'assets/treasy'
import TreasyTransparency from 'assets/treasy-transparency'
import ButtonImage from 'components/shared/button-image'
import { observer } from 'mobx-react-lite'
import { faBuildingColumns, faFolders, faReceipt, faSquarePen } from '@fortawesome/pro-regular-svg-icons'
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import useEmblaCarousel from 'embla-carousel-react'
import Modal from 'components/shared/modal'
import Ocr from 'assets/dataroom/ocr'
import FileImage from 'components/dataroom/spaceroom/file-image'
import Config from 'core/config'

type SearchResult = { uuid: string; fileName: string; url: string }

function MessageDisplay({
    conversationId,
    message,
    isLoading,
    index,
}: { conversationId: string; message: Answer; isLoading: boolean; index: number }) {
    const { t } = useTranslation()
    const { user } = useMst()

    const hasFinishedTool = message?.toolInvocations?.every(i => 'result' in i)
    const searchInvocation = message?.toolInvocations?.find(i => 'result' in i && i.toolName === 'search')
    let searchResults: SearchResult[] | undefined = undefined
    if (searchInvocation && 'result' in searchInvocation) {
        try {
            searchResults = (JSON.parse(searchInvocation.result as string) as SearchResult[]).filter(
                (value, index, self) => {
                    return self.findIndex(t => t.uuid === value.uuid) === index
                }
            )
        } catch (e) {
            // ignore
        }
    }

    return (
        <motion.div
            className={cn('flex flex-col gap-4 px-6', 'debugging' in message && 'bg-blue-100')}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {(!['user', 'human'].includes(message.role) || message.name) && (
                <div className="flex items-center gap-2">
                    <div
                        className={cn(
                            'rounded-50 size-10 shrink-0 overflow-hidden flex items-center justify-center bg-white text-black border',
                            'border-bittersweet'
                        )}
                    >
                        <Logo className="size-6" />
                    </div>

                    <span className="text-sm font-semibold">
                        <Treasy className="w-16" />
                    </span>
                </div>
            )}
            <div
                className={cn(
                    'flex flex-col gap-2',
                    ['user', 'human'].includes(message.role) &&
                        !message.name &&
                        'bg-white p-4 rounded max-w-fit place-self-end shadow min-w-1/2'
                )}
            >
                {(('loading' in message && typeof message.loading === 'string') || hasFinishedTool) && (
                    <div className="text-sm text-dark-grey-disabled flex gap-2 items-center">
                        <FontAwesomeIcon
                            icon={
                                'loading' in message && typeof message.loading === 'string' ? faSpinnerThird : faCheck
                            }
                            className={cn('w-4 h-4', hasFinishedTool && 'text-sushi')}
                            spin={'loading' in message && typeof message.loading === 'string'}
                        />
                        <span>
                            {'loading' in message && typeof message.loading === 'string'
                                ? message.loading
                                : t('web_ai_loading_done')}
                        </span>
                    </div>
                )}
                <Markdown
                    className={cn('[&_a]:underline')}
                    components={{
                        a: ({ node, href, children, ...props }) => {
                            return (
                                <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                                    {children}
                                </a>
                            )
                        },
                    }}
                >
                    {message.content}
                </Markdown>
                {!isLoading && hasFinishedTool && (
                    <div className="flex gap-4 py-4">
                        {searchResults?.map(r => (
                            <Link
                                key={r.uuid}
                                className="bg-white shadow size-36 flex flex-col gap-2 p-3 items-center justify-center"
                                to={r.url}
                            >
                                <FileImage file={r} filename={r.fileName} className="w-20 h-20" />
                                <span className="truncate max-w-full">{r.fileName}</span>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </motion.div>
    )
}

function ScrollToBottom() {
    const { isAtBottom, scrollToBottom } = useStickToBottomContext()

    return (
        !isAtBottom && (
            <div className="sticky bottom-2">
                <button
                    onClick={() => scrollToBottom()}
                    type="button"
                    className="absolute bottom-2 left-1/2 -translate-x-1/2 bg-atomic-tangerine rounded-full p-1 grid place-items-center"
                >
                    <FontAwesomeIcon icon={faArrowDown} className="text-white size-4 p-1" />
                </button>
            </div>
        )
    )
}

type Conversation = {
    uuid: string
    title: string
    createdAt: DateTime
    messages: { uuid: string }[]
}
type GroupedConversations = Record<string, { conversations: Conversation[]; date: DateTime }>

function History({ conversations }: { conversations: GroupedConversations }) {
    return (
        <AnimatePresence initial={false}>
            <ul className="flex flex-col gap-6">
                {Object.keys(conversations)
                    .sort((a, b) => {
                        const dateA = conversations[a].date
                        const dateB = conversations[b].date

                        return dateB.toMillis() - dateA.toMillis()
                    })
                    .map(key => {
                        return (
                            <li key={key}>
                                <strong className="block font-bold text-xs pb-2">{key}</strong>
                                <ul className="flex flex-col gap-3">
                                    {conversations[key].conversations.map(c => {
                                        return (
                                            <li key={c.uuid}>
                                                <Link to={`/ai/${c.uuid}`} className="truncate block text-regent-gray">
                                                    {c.title.split('').map((character, index) => (
                                                        <motion.span
                                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                                            key={index}
                                                            initial={{
                                                                opacity: 0,
                                                            }}
                                                            animate={{
                                                                opacity: 1,
                                                            }}
                                                            transition={{
                                                                duration: 0.125,
                                                                delay: index / 20,
                                                            }}
                                                        >
                                                            {character}
                                                        </motion.span>
                                                    ))}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        )
                    })}
            </ul>
        </AnimatePresence>
    )
}

function Carousel() {
    const [emblaRef] = useEmblaCarousel()
    const { t } = useTranslation()

    const slides = [
        { id: 1, icon: faFolders, text: t('web_ai_carousel_1') },
        { id: 2, icon: faBuildingColumns, text: t('web_ai_carousel_2') },
        { id: 3, icon: faReceipt, text: t('web_ai_carousel_3') },
    ]

    return (
        <div className="overflow-hidden w-3/4" ref={emblaRef}>
            <div className="w-full flex gap-2 items-center">
                <div className="flex w-full items-center">
                    {slides.map(slide => (
                        <div key={slide.id} className="min-w-0 flex-[0_0_33%] p-1">
                            <div key={slide.id} className="w-full border border-geyser rounded p-4 flex flex-col gap-1">
                                <FontAwesomeIcon icon={slide.icon} className="size-7 text-atomic-tangerine" />
                                <span>{slide.text}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const Page = observer(() => {
    const { t } = useTranslation()
    const locale = 'fr'

    const { conversationId } = useParams()

    const rootStore = useMst()
    const { aiSidebarOpened, user } = rootStore

    const url = new URL(`${Config.app.APIURL}/v1/web/ai/conversation/${conversationId}`)
    const { messages, setMessages, input, handleInputChange, handleSubmit, isLoading } = useChat({
        // streamProtocol: 'text',
        api: url.toString(),
        headers: {
            'X-API-KEY': Config.app.APIKEY ?? '',
            'X-APP-VERSION': `${Config.app.version.NUMBER}`,
            Authorization: `Bearer ${rootStore.token}`,
        },
        body: {
            locale,
        },
        onFinish() {
            refetch()
        },
    })

    const navigate = useNavigate()

    const aiAvailable = user.aiAvailableAt && user.aiAvailableAtDate <= DateTime.now()
    const canUseAi = aiAvailable && user.aiStatus === 'always'
    const aiReady = canUseAi && user.aiReadyAt && user.aiAvailableAtDate <= DateTime.now()

    const [showOcrAiModal, setShowOcrAiModal] = useState<boolean>(false)

    const changeOcrAiStatus = async (status: 'never' | 'always') => {
        user.setOcrStatus(status)
        user.setAiStatus(status)
        await user.update()
        setShowOcrAiModal(false)
    }

    useEffect(() => {
        if (aiAvailable && !canUseAi) {
            setShowOcrAiModal(true)
        }
    }, [aiAvailable, canUseAi])

    async function fetchData() {
        const { data } = await get<never, { data: (Omit<Conversation, 'createdAt'> & { createdAt: string })[] }>(
            '/v1/web/ai/conversations'
        )

        return data
            .map(c => {
                return { ...c, createdAt: DateTime.fromISO(c.createdAt) } as Conversation
            })
            .filter(c => c.messages.length > 0)
            .reduce((acc, conversation) => {
                const now = DateTime.now()

                const key = conversation.createdAt.toFormat(
                    conversation.createdAt.hasSame(now, 'year') ? 'MMMM' : 'MMMM yyyy'
                )
                if (!acc[key]) {
                    acc[key] = { conversations: [], date: conversation.createdAt.startOf('month') }
                }

                acc[key].conversations.push(conversation)
                acc[key].conversations.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())

                return acc
            }, {} as GroupedConversations)
    }

    const {
        refetch,
        isLoading: loadingHistory,
        data: conversations,
    } = useQuery({
        queryKey: ['ai_history'],
        queryFn: fetchData,
    })

    async function fetchConversation() {
        const { data } = await get<never, { data: { messages: Answer[] } }>(
            `/v1/web/ai/conversations/${conversationId}`
        )
        // console.log(data.messages)

        return data.messages
    }
    const { refetch: refetchConversation, isLoading: loadingConversation } = useQuery({
        refetchOnMount: false,
        enabled: false,
        queryKey: ['ai_convesation', conversationId],
        queryFn: fetchConversation,
    })

    useEffect(() => {
        if (conversationId) {
            // refetch()
            refetchConversation().then(data => setMessages(data.data))
        }
    }, [conversationId])

    async function newConversation() {
        const { data } = await post<void, { data: { conversationId: string } }>('v1/web/ai')
        navigate(`/ai/${data.conversationId}`)
    }

    function submit(e: FormEvent<HTMLFormElement> | undefined = undefined) {
        e?.preventDefault()

        if (!input || input === '') {
            return
        }

        handleSubmit(e, {
            // body: {
            //     conversationId: conversationId,
            //     locale,
            // },
        })
    }

    return (
        <ContentPage
            title={
                <div className="flex gap-2 items-center">
                    {!aiSidebarOpened && (
                        <>
                            <ButtonImage
                                tooltip={t('web_ai_toggle_sidebar')}
                                image={<FontAwesomeIcon icon={faSidebar} className="size-6 text-atomic-tangerine" />}
                                onClick={() => rootStore.setAiSidebarOpen(true)}
                            />
                            <ButtonImage
                                buttonClassName="bg-atomic-tangerine"
                                tooltip={t('web_ai_new_conversation')}
                                image={<FontAwesomeIcon icon={faSquarePen} className="size-6 text-white" />}
                                onClick={() => newConversation()}
                                disabled={isLoading}
                            />
                        </>
                    )}
                    <h1 className="mr-12 text-2xl font-bold">{t('web_ai')}</h1>
                </div>
            }
            containerClassnames="flex-row"
            leftComponent={
                <AnimatePresence initial={false}>
                    {canUseAi && aiSidebarOpened && (
                        <motion.div
                            initial={{ width: 0, opacity: 0 }}
                            animate={{ width: 320, opacity: 1 }}
                            exit={{ width: 0, opacity: 0 }}
                            className="bg-white min-h-full shadow-lg"
                        >
                            <div className="px-6 pt-8">
                                <div className="flex items-center justify-between">
                                    <ButtonImage
                                        tooltip={t('web_ai_toggle_sidebar')}
                                        image={
                                            <FontAwesomeIcon
                                                icon={faSidebar}
                                                className="size-6 text-atomic-tangerine"
                                            />
                                        }
                                        onClick={() => rootStore.setAiSidebarOpen(false)}
                                    />
                                    <ButtonImage
                                        buttonClassName="bg-atomic-tangerine"
                                        tooltip={t('web_ai_new_conversation')}
                                        image={<FontAwesomeIcon icon={faSquarePen} className="size-6 text-white" />}
                                        onClick={() => newConversation()}
                                        disabled={isLoading}
                                    />
                                </div>
                                <h3 className="font-bold text-2xl pt-10 pb-6">{t('web_ai_historic')}</h3>
                                <ScrollAreaPrimitive.Root
                                    className={cn('relative overflow-hidden h-[2000px] max-h-screen')}
                                >
                                    <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">
                                        {loadingHistory && (
                                            <FontAwesomeIcon icon={faSpinnerThird} className={cn('w-4 h-4')} spin />
                                        )}
                                        {conversations && <History conversations={conversations} />}
                                    </ScrollAreaPrimitive.Viewport>
                                    <ScrollAreaPrimitive.ScrollAreaScrollbar
                                        orientation="vertical"
                                        className={cn(
                                            'flex touch-none select-none transition-colors',
                                            'h-full w-2.5 border-l border-l-transparent p-[1px]'
                                        )}
                                    >
                                        <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
                                    </ScrollAreaPrimitive.ScrollAreaScrollbar>
                                    <ScrollAreaPrimitive.Corner />
                                </ScrollAreaPrimitive.Root>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            }
            showSearch={false}
        >
            <div className="flex flex-col h-screen w-full min-h-full">
                <div className="flex flex-col h-[calc(100%-400px)] w-full justify-center pt-2 pb-12 gap-6 xl:max-w-[1000px] 2xl:max-w-[1200px] mx-auto">
                    {messages?.length === 0 ? (
                        <div className="flex flex-col w-full items-center justify-center gap-8">
                            <TreasyTransparency className="max-w-40" />
                            <Carousel />
                        </div>
                    ) : (
                        <StickToBottom
                            initial="instant"
                            resize="smooth"
                            className="bg-background w-full h-full text-sm mx-auto relative pr-4 prose max-w-none"
                        >
                            <StickToBottom.Content className="flex flex-col gap-8 w-full pb-12">
                                {messages.map((m, i) => {
                                    return (
                                        <MessageDisplay
                                            key={m.id}
                                            index={i}
                                            conversationId={conversationId}
                                            message={m}
                                            isLoading={isLoading}
                                        />
                                    )
                                })}
                            </StickToBottom.Content>
                            <ScrollToBottom />
                        </StickToBottom>
                    )}
                </div>
                <div className="w-full flex items-center justify-center p-4">
                    {!canUseAi ? (
                        <p className="border border-geyser px-4 py-2 rounded text-sm">
                            {t('web_ai_not_available_yet')}
                        </p>
                    ) : (
                        !aiReady && (
                            <p className="border border-geyser px-4 py-2 rounded text-sm">
                                {t('web_ai_not_ready_yet')}
                            </p>
                        )
                    )}
                </div>
                <Textarea
                    disabled={!canUseAi || isLoading || loadingConversation}
                    input={input}
                    placeholder={messages.length === 0 ? t('web_ai_placeholder') : t('web_ai_placeholder_continue')}
                    // setInput={setInput}
                    submit={submit}
                    handleInputChange={handleInputChange}
                />
            </div>

            <Modal
                isOpen={showOcrAiModal}
                onRequestClose={() => setShowOcrAiModal(false)}
                size="1/2"
                overlayClassName="z-50"
            >
                <div className="flex w-full flex-col items-center justify-center">
                    <div className="flex w-full items-center justify-center gap-12">
                        {user.ocrStatus === 'not_configured' && <Ocr className="w-20 text-christine" />}
                        <FontAwesomeIcon icon={faWandSparkles} className="size-20 text-christine" />
                    </div>
                    <h3 className="mt-12 text-xl font-bold">
                        {t(user.ocrStatus === 'not_configured' ? 'web_ocr_ai_new_title' : 'web_ai_new_title')}
                    </h3>
                    <h4 className="mt-12 text-lg font-bold">
                        {t(user.ocrStatus === 'not_configured' ? 'web_ocr_ai_new_subtitle' : 'web_ai_new_subtitle')}
                    </h4>
                    <div className="prose mt-4 text-left">
                        <Trans
                            i18nKey={user.ocrStatus === 'not_configured' ? 'web_ocr_ai_new_desc' : 'web_ai_new_desc'}
                        >
                            {t(user.ocrStatus === 'not_configured' ? 'web_ocr_ai_new_desc' : 'web_ai_new_desc')}
                        </Trans>
                    </div>
                    <p className="mt-12 font-bold">
                        {t(user.ocrStatus === 'not_configured' ? 'web_ocr_ai_new_want_try' : 'web_ai_new_want_try')}
                    </p>
                    <div className="flex justify-around pb-4 items-center">
                        <button
                            type="button"
                            className="btn float-left mr-4 mt-5 inline-flex cursor-pointer"
                            onClick={() => changeOcrAiStatus('always')}
                        >
                            {t('web_ocr_new_activate_always')}
                        </button>
                        <button
                            type="button"
                            className="btn white float-left mr-4 mt-5 inline-flex cursor-pointer"
                            onClick={() => changeOcrAiStatus('never')}
                        >
                            {t('web_ocr_new_fear_ocr')}
                        </button>
                    </div>
                </div>
            </Modal>
        </ContentPage>
    )
})

export default Page
